*,*::before,*::after{
    box-sizing: border-box;
}

body{
    margin: 0;
    font-family: "PT Sans", sans-serif;
}
a{
    text-decoration: none;
}

img{
    max-width: 100%;
}

p{
    margin: 0;
    padding: 0;
}
$max-width: 1200px;
$header-height: 3rem;

$border-color: #ccc;
$text-color: #f7f7fe;
$color-primary:  #141212;
$color-secondary:  #222020;
$color-tertiary: #6a26cd;
$color-auxiliary: #37b9f1;
$color-grey: #515151;

$breakpoints: (
  lx:1020,
  l: 960px,
  s: 500px,
);

// Variables de apilamiento (z-index)
:root {
    --z-back: -10;
    --z-normal: 1;
    --z-tooltip: 10;
    --z-tooltip2: 50;
    --z-fixed: 100;
    --z-modal: 1000;
}


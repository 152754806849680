@mixin fromMin($bp) {
    @if map-has-key($breakpoints, $bp) {
      @media screen and (min-width : map_get($breakpoints, $bp)) {
        @content;
      }
    } @else {
      @media screen and (min-width : $bp) {
        @content;
      }
    }
  }


  @mixin fromMax($bp) {
    @if map-has-key($breakpoints, $bp) {
      @media screen and (max-width : map_get($breakpoints, $bp)) {
        @content;
      }
    } @else {
      @media screen and (max-width : $bp) {
        @content;
      }
    }
  }

/*------------------ C O N T E N I D O ------------------*/

.errorData{
    display: flex;
    justify-content: center;
    @include fromMax(l){
        flex-direction: column;
            align-items: center;
    } 
    
}

.content-error{
    display: flex;
    justify-content: center;

    @include fromMax(l){
        flex-direction: column;
            align-items: center;
    } 
}

.contenedor-imagen{
    margin-right: 3rem;
}

.content-error{
    display: flex;
    flex-direction: column;
}

.content-error .error p{
    color: #5D5D5D;
    font-size: 18rem;

    @include fromMax(l){
        color: white;
            font-size: 10rem;
    } 
}

.content-error .mensaje p{
    color: #A6A6A6;
    font-size: 1.2rem;
    text-align: center;

    @include fromMax(l){
        color: white;
        font-size: 1rem;
    } 
}
.content-error .mensaje{
  
    @include fromMax(l){
        margin-left: 2rem;
        margin-right: 2rem;
    } 
}

.content-enlace{
    display: flex;
    justify-content: center;
}

.content-enlace a{
    text-decoration: none;
}

span {
    padding: 0;
    display: inline-block;
    font-size: 1.5rem;
    color: $color-auxiliary;
}

span:hover {
    color: $color-tertiary;
    transition: all 0.5;
}

.contenedor-imagen{
    margin-top: 5rem;

    @include fromMax(l){
        margin-right: 0;
    } 
}


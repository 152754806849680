.main-menu {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    
    @include fromMin(l) {
        display: flex;
    }
}

.main-menu__item {
    padding: 1rem 1rem;
}

.main-menu__link {
    color: $text-color;
    margin-left: 1rem;

    &:hover{
        color: $color-tertiary;
    }
}

.main-nav {
    position: fixed;
    height: calc(100vh - #{$header-height});
    width: 80vw;
    background-color: $color-primary;
    top: $header-height;
    right: -80vw;
    transition: right 0.3s;
    padding-top: 1rem;
    z-index: var(--z-fixed);

    @include fromMin(l) {
        position: static;
        background: transparent;
        padding-top: 0;
        height: auto;
        width: auto;
    }

    &.show {
        right: 0;
    }
}

.main-menu-toggle {
    $color: $text-color;
    width: 2rem;
    height: 1.6rem;
    cursor: pointer;
    background: linear-gradient(
        $color 20%,
        transparent 20%,
        transparent 40%,
        $color 40%,
        $color 60%,
        transparent 60%,
        transparent 80%,
        $color 80%,
        $color 100%
    );

    @include fromMin(l) {
        display: none;
    }
}
